@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

.container {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;


}

.linkHref {
    border-radius: 10px;
    position: relative;
    display: inline-block;
    padding: 15px 30px;
    border: 2px solid #36454F;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 2px;
    color: #fff;
    -webkit-box-reflect: below 0px linear-gradient(transparent, #0000);
    transition: 0.5s;
    transition-delay: 0s;
    font-size: 0.9rem;
    background-color: rgba(140, 3, 28, 0.6);
    margin: 20px;


}

.linkHref:hover {
    color: whitesmoke;
    transition: color 2s, background-color 2.5s, font-size 2s, box-shadow 1s;
    border-radius: 5px;
    background-color: rgba(191, 4, 38, 1);
    font-size: 1.5rem;


    box-shadow: 0 0 5px #36454F,
        0 0 10px #6699cc,
        0 0 20px #6699cc,
        0 0 30px #36454F,
        0 0 40px #36454F;

}

.container span {
    position: relative;
    z-index: 100;
}


.linkHref:nth-child(2) {
    filter: hue-rotate(80deg);
}


@media screen and (max-width: 960px) {

    .spanmain {
        font-size: 1rem;

    }


    .linkHref {

        padding: 20px;

    }

    .container {

        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        border-radius: 10px;


    }
}