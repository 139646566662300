.blog-post{
width: 100%;
max-width: 80%;
padding: 5rem;
background: linear-gradient(0deg, #000000, RGBA(140, 3, 28,0.7));
box-shadow: 0 1.4rem 8rem rgba(0,0,0,0.2);
display: flex;
align-items: center;
margin-top: 4vh;

border-radius: 0.8rem;
color: #fff;
font-size: 1.3rem;
transition: all 2s ease-in-out;
}


.blog-post__img{
min-width: 20rem;
max-width: 20rem;
height: 15rem;
transform: translateX(-8rem);
position: relative;
transition: all 2s ease-in-out;
}

.blog-post__img img{
    height: 100%;
    width: 130%;
    object-fit: cover;
    
    display: block;
    border-radius: 0.8rem;
    transition: all 2s ease-in-out;
    
}
.informationShorts{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    transition: all 2s ease-in-out;
}
.information_main{
    border-top: 1px solid white;
    background: url('./eventbackdrop.jpg');
    padding-bottom: 4vh;
    align-items: center;
    justify-self: center;
    transition: all 2s ease-in-out;
}

.headingText{

    padding: 2vh;
    background-size: cover;
    background-color: #000000;
    justify-self: left;
    justify-content: left;
    border-bottom-right-radius: 30px;
    font-size: 3rem;
    color: whitesmoke;
    
    border-bottom: 1.15px solid white;
    border-right: 1.15px solid white;
    width: fit-content;
    transition: all 2s ease-in-out;
}

.card_wrapper{
    display: flex;
    flex-direction: column;

}

.main-wrapper{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}
.card_wrapper{
    background-color: #36454F;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    
}


@media screen and (max-width: 960px) {

    .information_main{
        border-top: 1px solid white;
        background: url('./eventbackdrop.jpg');
        padding-bottom: 4vh;
        align-items: center;
        justify-self: center;
        transition: all 2s ease-in-out;
    }
    
    .headingText{
    

        font-size: 2rem;
        transition: all 2s ease-in-out;

    }

    h1{
        font-size: 1.8rem;
        transition: all 2s ease-in-out;
        padding-left: 1rem;
        padding-top: 1rem;
    }
    p{
        font-size: 1rem;
        transition: all 2s ease-in-out;
        padding: 1rem;
    }

    .blog-post__img img{
        height: 110%;
        width: 100%;
        object-fit: cover;
        border-top: solid 5px rgba(191, 4, 38, 1);
        align-items: center;
        display: flex;
        flex-direction: column;

        justify-self: center;
        align-self: center;
        border-radius: 0.8rem;
        transition: all 2s ease-in-out;

    }

    .blog-post__img{
        height: 100%;
        width: 50%;
        object-fit: cover;
        align-items: center;
        display: flex;
        flex-direction: column;
        
        justify-self: center;
        align-self: center;
        border-radius: 0.8rem;
        transition: all 2s ease-in-out;
        align-items: center;
        height: 8rem;
        transform: translateX(0rem);
        transform: translateY(0rem);
        
        transition: all 2s ease-in-out;
        }

        .blog-post{
            padding: 2px;
            
            
            justify-content: center;
            justify-items: center;
            flex-direction: column;
            transition: all 2s ease-in-out;
        }

}